<template>
  <div class="p-2">
    <div class="card">
      <form class="app-search">
        <div class="form-group w-50 mb-2">
          <label for="per-page">Nombre par page:</label>
          <input
            type="number"
            id="per-page"
            class="form-control"
            step="5"
            min="10"
            max="100"
            v-model="perPage"
          />
        </div>
        <div class="app-search-box">
          <div class="input-group">
            <input
              type="search"
              v-model="search"
              class="form-control"
              placeholder="Rechercher par titre, prix, devise,id..."
            />
          </div>
        </div>
      </form>
    </div>

    <div class="card">
      <table id="basic-datatable" class="table dt-responsive nowrap w-100">
        <thead>
          <tr>
            <th>#</th>
            <th>Formation</th>
            <th>Eleve</th>
            <th>Somme verse</th>
            <th>Devise</th>
            <th>Debut</th>
            <th>Fin</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in paginate" :key="c.id">
            <td>{{ c.id }}</td>
            <td>
              {{ c.course }}
            </td>
            <td>{{ c.user.firstName + " " + c.user.lastName }}</td>
            <td>
              {{ c.price }}
            </td>
            <td>
              {{ c.currency }}
            </td>
            <td>
              {{ c.startDate }}
            </td>
            <td>
              <input
                @change="update(c)"
                type="date"
                v-model="c.endDate"
                formatter=""
                class="form-control"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="perPage < enrolments.length"
        class="d-flex justify-content-around border mt-1"
      >
        <button
          :disabled="page === 1"
          class="btn btn-sm btn-info"
          @click="prev"
        >
          <i class="fas fa-arrow-left"></i>
        </button>
        <p>page {{ page }}/{{ totalPages }}</p>
        <button
          :disabled="page == totalPages"
          class="btn btn-sm btn-info"
          @click="next"
        >
          <i class="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
    <div class="card">
      <h3>Inscription manuel</h3>
      <div class="row">
        <div class="col-md-6">
          <label for="">Eleve:</label>
          <AutocompleteSurch
            @selected="selectUser"
            :searchKey="'email'"
            :className="'form-select'"
            :items="students"
          />
        </div>
        <div class="col-md-6">
          <label for="">Formation:</label>
          <AutocompleteSurch
            @selected="selectCourse"
            :className="'form-select'"
            :searchKey="'title'"
            :items="courses"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="begin">Prix:</label>
            <input
              v-model="newEnrolment.price"
              type="number"
              step="0.1"
              id="begin"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label for="devise">Devise:</label>
            <select
              v-model="newEnrolment.currency"
              id="devise"
              class="form-select"
            >
              <option value="XAF">XAF</option>
              <option value="XOF">XOF</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3">
          <button @click="enrol" class="btn btn-primary">Appliquer</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AutocompleteSurch from "../../components/shared/AutocompleteSurch.vue";
export default {
  components: {
    AutocompleteSurch,
  },
  data() {
    return {
      enrolments: [],
      perPage: 10,
      page: 1,
      search: "",
      students: [],
      courses: [],
      newEnrolment: {
        user: 0,
        price: 0,
        course: 0,
        currency: "XAF",
      },
    };
  },
  methods: {
    selectUser({ id }) {
      this.newEnrolment.user = id;
    },
    selectCourse({ id }) {
      this.newEnrolment.course = id;
    },
    async loadEnrolments() {
      this.$store.dispatch("loadEnrolments").then((enrolments) => {
        this.enrolments = enrolments;
      });
    },
    async loadStudents() {
      this.$store
        .dispatch("loadStudents")
        .then((students) => (this.students = students));
    },
    async loadcourses() {
      this.$store.dispatch("loadCourses").then((courses) => {
        this.courses = courses;
      });
    },
    async enrol() {
      await this.$http
        .post("/enrolment/enrol-user-in-course", this.newEnrolment)
        .then(() => {
          this.$store.state.enrolments.updated = false;
          this.$toast.success("success!");
          this.newEnrolment = {
            user: 0,
            price: 0,
            course: 0,
            currency: "XAF",
          };
          this.loadEnrolments();
        });
    },
    async update(c) {
      await this.$http
        .put(`/enrolment/extend-user-end-date/${c.id}`, c)
        .then(() => {
          this.$store.state.enrolments.updated = false;
          this.$toast.success("success!");
          this.loadEnrolments();
        });
    },
    prev() {
      if (this.page > 1) this.page--;
    },
    next() {
      if (this.page < this.totalPages) this.page++;
    },
  },
  computed: {
    totalPages() {
      return Math.ceil(this.enrolments.length / this.perPage);
    },
    paginate() {
      let start = (this.page - 1) * this.perPage;
      if (start < 0) start = 1;
      return this.searchResult.slice(start, start + this.perPage);
    },
    searchResult() {
      if (!this.search) return this.enrolments;
      return this.enrolments.filter(
        (s) =>
          s.id == this.search ||
          s.course.toLowerCase().includes(this.search) ||
          s.currency.toLowerCase().includes(this.search) ||
          s.user.firstName.toLowerCase().includes(this.search) ||
          s.user.lastName.toLowerCase().includes(this.search) ||
          s.user.email.toLowerCase().includes(this.search) ||
          s.price == this.search
      );
    },
  },
  beforeMount() {
    this.$nextTick(() => {
      this.loadEnrolments();
      this.loadStudents();
      this.loadcourses();
    });
  },
};
</script>
<style lang=""></style>
