<template lang="">
  <div :class="className">
    <input
      type="search"
      name="text"
      autocomplete="off"
      class="search-input form-control"
      v-model="emailForSearch"
      :placeholder="searchKey + '...'"
    />
    <div class="proposed-items-block">
      <ul class="list-group" v-if="emailForSearch != '' && !emited">
        <li
          @click="emitItem(item)"
          class="list-group-item proposed-item"
          v-for="(item, index) in sortedItems"
          :key="index"
        >
          {{ item[searchKey] }}
        </li>
      </ul>
      <div
        v-if="emailForSearch != '' && sortedItems.length <= 0"
        class="alert alert-danger bg-transparent"
      >
        Not found!
      </div>
    </div>
    {{ selectedItem }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      emailForSearch: "",
      selectedItem: null,
      emited: false,
    };
  },
  props: {
    items: Array,
    searchKey: String,
    className: {
      type: String,
      default: "",
    },
  },
  computed: {
    sortedItems() {
      return this.items?.filter((e) =>
        e[this.searchKey]
          .toLowerCase()
          .includes(this.emailForSearch.toLowerCase())
      );
    },
  },
  methods: {
    emitItem(item) {
      this.emailForSearch = item[this.searchKey];
      this.$emit("selected", item);
      this.emited = true;
    },
  },
};
</script>
<style lang="css">
.proposed-items-block,
.search-input {
  width: 100%;
  overflow: hidden;
}
.list-group,
.list-group-item {
  width: 100%;
}
</style>
